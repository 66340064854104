import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"

import emailToClinicNewLetter from "src/assets/video/clinic-email-new-patient-letter.gif"
import padlock from "src/assets/img/graphics/padlock.svg"

const Page = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: For clinics"
        pathname="/for-clinics"
        description="If you are a clinic who has received a secure letter via Carebit, learn more about what Carebit is and how it can help you."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Clinic information
              </Badge>
              <h1>Carebit for clinics</h1>
              <p className="u-whiteTranslucent">
                Carebit is a digital healthcare platform that is designed to
                help clinics automate their processes and share patient data
                easily, securely and efficiently. You can find more information
                on how it works below.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col md={{ size: 6 }} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Sharing is caring">
                        Quickly receive and access shared letters
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit offers a convenient, secure way to receive letters
                      that’s compliant with your data protection obligations to
                      patients. No more letters lost in the post, faxes gone
                      astray or having to scan in paper documents.
                    </p>
                    <p>
                      It’s all stored and transmitted securely through Carebit,
                      and you’ll receive an email notification when a new letter
                      has been shared with your clinic.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - email to clinic"
                      src={emailToClinicNewLetter}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={padlock} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Safe & secure">
                        Encryption used throughout
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      With Carebit, you can be sure that data is safe with us.
                      All data is encrypted at rest in our data centre and you
                      only access it with encrypted links that expire after 15
                      minutes, providing additional security. When you connect
                      to Carebit, it’s only ever over an encrypted SSL
                      connection.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/security">
                        <Button color="secondary" size="md">
                          Learn more about Carebit security &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
        <section className="u-bgLightestGrey">
          <div className="u-centerFlex u-padding20">
            <Col lg={6} md={12}>
              <h2 style={{ marginBottom: "40px" }}>
                Frequently asked questions
              </h2>

              <h5>
                I’ve received an email from Carebit saying I have a new letter
                to view. What does this mean?
              </h5>
              <p>
                This means that a clinic using Carebit has shared a patient
                letter with you by granting you permission to view it securely.
                You are able to retrieve a digital copy of the letter by
                confirming your Carebit account, creating a staff member and
                logging in. From there, you can view a PDF of the letter and
                then handle it according to your internal processes.
              </p>

              <h5 style={{ marginTop: "40px" }}>
                Why can’t these letters be faxed or posted instead?
              </h5>
              <p>
                The NHS is committed to reducing the use of fax machines, and
                from April 2020 they will be banned entirely. Often faxes are
                sent to the wrong number or misplaced/lost, while posting is
                slow, expensive and insecure.
              </p>
              <p>
                By contrast, Carebit offers an seamless, permanent,
                environmentally-friendly, instant and secure way of sharing
                confidential patient letters between authorised clinics. The
                letter never leaves Carebit and is encrypted at both ends, so
                there’s no opportunity for interception or data loss.
              </p>

              <h5 style={{ marginTop: "40px" }}>
                How much does it cost to receive a letter?
              </h5>
              <p>It’s completely free to receive letters through Carebit.</p>

              <h5 style={{ marginTop: "40px" }}>
                There is more than one member of staff at my clinic. Can I
                create more staff member accounts?
              </h5>
              <p>
                You can easily add more staff member accounts for free. In
                Carebit, just go to Settings, then Staff Members.
              </p>
            </Col>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Page
